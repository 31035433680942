import {Routes} from '@angular/router';
import {AuthGuard} from "./core";

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadComponent: () => import('./components/index').then(com => com.GatePassGridComponent),

    },
    {
        path: 'create-gate-pass',
        loadComponent: () => import('./components/index').then(com => com.CreateGatePassComponent),
        canActivate: [AuthGuard], data: { requiredAction: 'create' }
    },
    {
        path: 'view-gate-pass',
        loadComponent: () => import('./components/index').then(com => com.GatePassGridComponent),
        canActivate: [AuthGuard], data: { requiredAction: 'read' }
    },
    {
        path: 'return-gate-pass',
        loadComponent: () => import('./components/index').then(com => com.ReturnGatePassComponent),
        canActivate: [AuthGuard], data: { requiredAction: 'return' }
    },
    {
        path: 'error',
        loadComponent: () => import('./components/index').then(com => com.ErrorPageComponent),
    },
    {
        path: '**',
        redirectTo: '/home'
    }

];